

import DeleteCompanyModal from 'root/app/components/settings/settings_advisor/modals/deleteCompany.component';
import ResetCompanyModal from 'root/app/components/settings/settings_advisor/modals/resetCompany.component';

function SettingsAdvisorCtrl($scope, AuthSrvc, CompanySvrc, ErrorSvrc, $translate, Analytics, toaster, $window, DeleteCompanyModal, ResetCompanyModal) {
    'ngInject'

    $scope.AuthSrvc = AuthSrvc;
    
    $scope.companyLaunchStatus = [
        { id: 'CREATED', label: $translate.instant('COMPANY_LAUNCH_STATUS_SPEED_CREATED') },
        { id: 'PRELAUNCH', label: $translate.instant('COMPANY_LAUNCH_STATUS_PRELAUNCH') },
        { id: 'LAUNCHED', label: $translate.instant('COMPANY_LAUNCH_STATUS_LAUNCHED') },
        { id: 'CRUISE_SPEED', label: $translate.instant('COMPANY_LAUNCH_STATUS_CRUISE_SPEED') }
    ];

    $scope.mailVoteModes = [
        { id: 'DISABLED', label: $translate.instant('COMPANY_MAIL_VOTE_DISABLED') },
        { id: 'EACH_WEEK', label: $translate.instant('COMPANY_MAIL_VOTE_EACH_WEEK') },
        { id: 'EACH_TWO_WEEKS', label: $translate.instant('COMPANY_MAIL_VOTE_EACH_TWO_WEEKS') }
    ];

    $scope.companyStatusModes = [
        { id: 'ACTIVE', label: $translate.instant('COMPANY_ACTIVE') },
        { id: 'BLOCKED', label: $translate.instant('COMPANY_BLOCKED') }
    ];

    $scope.hiCalculationModes = [
        { id: 'CLASSIC', label: $translate.instant('CLASSIC_HI_CALCULATION_MODE') },
        { id: 'POSITIVENESS', label: $translate.instant('POSITIVENESS_HI_CALCULATION_MODE') },
        { id: 'AVERAGES', label: $translate.instant('AVERAGES_HI_CALCULATION_MODE') }
    ];


    $scope.companyPlanTypes = [
        { id: 'TEST', label: 'Test company' },
        { id: 'TRIAL', label: 'Trial' },
        { id: 'SUBSCRIPTION', label: 'Subscribed' }
    ];

    $scope.companySourceTypes = [
        { id: 'SELF_SERVICE', label: 'Self Service' },
        { id: 'SALE', label: 'Sale' },
        { id: 'PARTNER_SALE', label: 'Partner Sale' }
    ];



    $scope.votingFlow = [
        { id: 'QUESTIONS', label: $translate.instant('QUESTIONS') },
        { id: 'ANNOUNCEMENTS', label: $translate.instant('SETTINGS_COMMUNICATE_ANNOUNCEMENTS') }
    ];


    $scope.heroRequiredRoles = [
        { id: 'ADMIN', label: 'Solo Admins' },
        { id: 'MANAGER', label: 'Admins y Managers' },
        { id: 'EMPLOYEE', label: 'Todo el mundo, incluidos empleados' }
    ];

    $scope.monthlyReportOnlyForModes = [
        { id: null, label: 'Nadie' },
        { id: 'ADMIN', label: 'Solo Admins' },
        { id: 'ADMIN_MANAGER', label: 'Admins y Managers' }
    ];



    $scope.newCompanyStatus = null;


    $scope.deleteCompany = function () {

        CompanySvrc.getUserCompany(
            function (err, company) {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    DeleteCompanyModal.showModal(company, function (secretCompanyDeletePhrase) {
                        CompanySvrc.deleteCompany(company.id, secretCompanyDeletePhrase,
                            function (err) {
                                if (err) {
                                    ErrorSvrc.showErrorModal(err);
                                } else {
                                    toaster.pop('success', null, ($translate.instant('COMPANY_DELETED')));
                                    $window.location.reload();
                                }
                            });
                    });
                    Analytics.trackEvent('settings_advisor', 'delete_company');
                }

            }
        );


    };

    $scope.resetCompany = function () {

        CompanySvrc.getUserCompany(
            function (err, company) {
                if (err) {
                    ErrorSvrc.showErrorModal(err);
                } else {
                    ResetCompanyModal.showModal(company, function (secretCompanyResetPhrase) {
                        CompanySvrc.resetCompany(company.id, secretCompanyResetPhrase,
                            function (err) {
                                if (err) {
                                    ErrorSvrc.showErrorModal(err);
                                } else {
                                    toaster.pop('success', null, ($translate.instant('COMPANY_RESETED')));
                                    $window.location.reload();
                                }
                            });
                    });
                    Analytics.trackEvent('settings_advisor', 'delete_company');
                }

            }
        );
        Analytics.trackEvent('settings_advisor', 'reset_company');
    };

    $scope.changeCompanyStatus = function (newCompanyStatus) {
        CompanySvrc.changeCompanyStatus(newCompanyStatus, function (err, result) {
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                toaster.pop({
                    type: 'success',
                    title: 'Estado cambiado a ' + newCompanyStatus,
                    showCloseButton: true,
                    timeout: 5000
                });

                $window.location.reload();

            }
        });
    };

    $scope.hasStartDate = null;
    if ($scope.companyConfig.companyStartDate) {
        $scope.hasStartDate = true;
    }

    $scope.showDateInput = function () {
        $scope.hasStartDate = true;
    };

    

    $scope.toggleLoginMethod = function(method) {

        const index = $scope.companyConfig.allowedLoginMethods.indexOf(method);

        if (index === -1) {
            // Si el método no está en el array, lo añadimos
            $scope.companyConfig.allowedLoginMethods.push(method);
        } else {
            // Si ya está, lo eliminamos
            $scope.companyConfig.allowedLoginMethods.splice(index, 1);
        }
    };



 
}

const hfSettingsAdvisor = angular.module('happyForceApp')
    .directive('hfSettingsAdvisor', function () {
        return {
            template: require('./settings_advisor.html'),
            controller: SettingsAdvisorCtrl,
            controllerAs: 'sac',
            bindToController: true
        };
    });

export default hfSettingsAdvisor;
