import angular from 'angular';
import CompanySvrc from './company.service';
import AuthSrvc from './auth.service';
import GroupsService from './groups.service';
import Characteristics from './characteristics.service';
import CompanyStatusService from './company-status.service';
import Languages from './languages.services';
import ErrorService from './error.service';
import FilterService from './filters.service';
import Reports from './reports.service';
import Anonymity from './migrated/anonymity.service';
import Announcements from './announcements.service';
import AnnouncementsSectionsConfiguration from './announcements_configuration.service';
import ParticipationService from './participation.service';
import HierarchyService from './migrated/hierarchy.service';
import HIService from './hi.service';
import MilestoneService from './milestone.service';
import CommentsService from './comments.service';
import Conversation from './conversations.service';
import AttachementsService from './attachment.service';
import ClassificiationService from './classification.service';
import CompanyCustomizationService from './company-customization.service';
import CountryService from './countries.service';
import CronService from './cron.service';

import EmployeeService from './employees.service';

import FeedbackConfigurationService from './feedback_configuration.service';
import FeedbackContainersService from './feedback_containers.service';
import FeedbackIdeasService from './feedback_ideas.service';
import FeedbackStatusService from './feedback_status.service';
import FileSaver from './filesave.service';
import HFController from './hfcontroller.service';
import HRSISettinsService from './HRSISettings.service';
import InsightsService from './insights.service';
import LaunchService from './launch.service';
import LoadingService from './loading.service';
import ManagersActivityService from './manager_activity.service';
import ManagersRankingService from './managers_ranking.service';
import MonitoringAlertsService from './monitoring_alert.service';
import MonitoringRulesService from './monitoringrule.service';
import PeopleService from './people.service';
import PreviewService from './preview.service';
import QuestionsService from './questions.service';
import RankingService from './ranking.service';
import RecognitionService from './recognition.service';
import ScoresService from './scores.service';
import ScoresAdvisorsService from './scores-advisors.service';
import ScoresV2Service from './scoresV2.service';
import SegmentationNamesService from './segmentationNames.service';
import SilosService from './silos.service';
import anchorSmoothScrollService from './smothscroll.service';
import StatsService from './stats.service';
import './stripe.service';
import SurveysService from './surveys.service';
import SurveyAdvisorsService from './surveys_advisors.service';
import TopicsService from './topics.service';
import UnsplashService from './unsplash.service';
import UserService from './users.service';
import SegmentsFilterService from './segments-filter.service';
import ReportsService from './reports.service';

/*
    TODO:
 */


/* Export of services */
angular.module('happyForceApp').service('CompanySvrc',  CompanySvrc);
angular.module('happyForceApp').service('AuthSrvc',  AuthSrvc);
angular.module('happyForceApp').service('Groups',GroupsService);
angular.module('happyForceApp').service('Languages',  Languages);
angular.module('happyForceApp').service('Characteristics', Characteristics);
angular.module('happyForceApp').service('CompanyStatus', CompanyStatusService);
angular.module('happyForceApp').service('ErrorSvrc', ErrorService);
angular.module('happyForceApp').service('Filters', FilterService );
angular.module('happyForceApp').service('Reports',Reports);

angular.module('happyForceApp').service('Announcements',Announcements);
angular.module('happyForceApp').service('AnnouncementsSectionsConfiguration', AnnouncementsSectionsConfiguration);
angular.module('happyForceApp').service('ParticipationService', ParticipationService);

angular.module('happyForceApp').service('HIService',HIService);
angular.module('happyForceApp').service('Milestone', MilestoneService );
angular.module('happyForceApp').service('Comments',CommentsService);
angular.module('happyForceApp').service('Conversation', Conversation);
angular.module('happyForceApp').service('Attachments', AttachementsService);
angular.module('happyForceApp').service('ClassificationService',ClassificiationService);
angular.module('happyForceApp').service('CompanyCustomization',  CompanyCustomizationService);
angular.module('happyForceApp').service('Countries',CountryService);
angular.module('happyForceApp').service('CronService',CronService);

angular.module('happyForceApp').service('Employees', EmployeeService);

angular.module('happyForceApp').service('FeedbackConfigurationService', FeedbackConfigurationService );
angular.module('happyForceApp').service('FeedbackContainersService', FeedbackContainersService);
angular.module('happyForceApp').service('FeedbackIdeasService', FeedbackIdeasService);
angular.module('happyForceApp').service('FeedbackStatusService',FeedbackStatusService);
angular.module('happyForceApp').service('FileSaver', FileSaver  );
angular.module('happyForceApp').service('HFController', HFController);
angular.module('happyForceApp').service('HRSIService',HRSISettinsService);
angular.module('happyForceApp').service('InsightsService', InsightsService);
angular.module('happyForceApp').service('LaunchService',LaunchService );
angular.module('happyForceApp').service('LoadingService', LoadingService);
angular.module('happyForceApp').service('ManagersActivity', ManagersActivityService);
angular.module('happyForceApp').service('ManagersRanking',  ManagersRankingService);
angular.module('happyForceApp').service('MonitoringAlerts',MonitoringAlertsService );
angular.module('happyForceApp').service('MonitoringRules', MonitoringRulesService );
angular.module('happyForceApp').service('People', PeopleService);
angular.module('happyForceApp').service('PreviewService',PreviewService  );
angular.module('happyForceApp').service('Questions', QuestionsService);
angular.module('happyForceApp').service('RankingService', RankingService);
angular.module('happyForceApp').service('RecognitionService',  RecognitionService);
angular.module('happyForceApp').service('Scores',ScoresService  );
angular.module('happyForceApp').service('ScoresAdvisor', ScoresAdvisorsService  );
angular.module('happyForceApp').service('ScoresV2Srvc',ScoresV2Service  );
angular.module('happyForceApp').service('SegmentationNamesService', SegmentationNamesService);
angular.module('happyForceApp').service('Silos', SilosService);
angular.module('happyForceApp').service('anchorSmoothScroll',anchorSmoothScrollService );
angular.module('happyForceApp').service('Stats', StatsService);
angular.module('happyForceApp').service('Surveys', SurveysService);
angular.module('happyForceApp').service('SurveyAdvisors', SurveyAdvisorsService);
angular.module('happyForceApp').service('Topics', TopicsService );
angular.module('happyForceApp').service('UnsplashService',UnsplashService);
angular.module('happyForceApp').service('UsersSrvc', UserService );
angular.module('happyForceApp').service('SegmentsFilterService', SegmentsFilterService);
angular.module('happyForceApp').service('Reports',ReportsService);



// Migration from old services
//angular.module('happyForceApp').service('ActivationService', Activation );
//angular.module('happyForceApp').service('ENPSSrvc', ENPSService  );
//angular.module('happyForceApp').service('Hierarchy', HierarchyService);
//angular.module('happyForceApp').service('CultureValuesService', CultureValuesService);
