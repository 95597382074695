

import getSettingsSections from './sections';

import template from './settings.html';
import Dates from '../../utilities/date.utilities';

// Add all imports:
import hfCompanyInfo from './settings_basic/settings_company_info/settings_company_info.controller';
import hfCompanyLocalization from './settings_basic/settings_company_localization/settings_company_localization.controller';
import hierarchyConfig from './settings_segmentation/hierarchy_config/hierarchy_config.controller';
import characteristicsConfig from './settings_segmentation/characteristics_config/characteristics_config.controller';
import groupsConfig from './settings_segmentation/groups_config/groups_config.controller';
import hiQuestionConfig from './settings_measure/hi_question_config/hi_question_config.controller';
import hfSettingsEnps from './settings_measure/enps_config/enps_config.controller';
import hfSettingsScores from './settings_measure/scores_config/settings_scores.controller';
import hfMeasureTransparencyConfig from './settings_measure/transparency_config/settings_measure_transparency_config.controller';
import hfCongratulationFeedbackConfig from './settings_feedback/congratulation_config/congratulation_feedback_config.controller';
import hfIdeasFeedbackConfig from './settings_feedback/ideas_config/ideas_feedback_config.controller';
import hfInformationFeedbackConfig from './settings_feedback/information_config/information_feedback_config.controller';
import hfSomethingIsWrongFeedbackConfig from './settings_feedback/somethingiswrong_config/somethingiswrong_feedback_config.controller';
import recognitionConfig from './settings_feedback/recognition_config/recognition_config.controller';
import hfFeedbackReplyConfig from './settings_feedback/reply_config/feedback_reply_config.controller';
import hfAnnouncementsConfig from './settings_communicate/settings_announcements/announcements_config.controller';
import hfWidgetIntegrationSettings from './settings_integration/settings_widget_integration/settings_widget_integration.controller';
import hfApiIntegrationSettings from './settings_integration/settings_api_integration/settings_api_integration.controller';
import hfSettingsCustomization from './settings_mail_and_notifications/settings_customization/settings_customization.controller';
import hfSettingsAlertsAndReminders from './settings_mail_and_notifications/settings_alerts_reminders/SettingsAlertsAndReminders';
import hfSettingsEngagementMails from './settings_mail_and_notifications/settings_engagement_mails/settings_engagement_mails.controller';
import hfSettingsNotifications from './settings_mail_and_notifications/settings_notifications/SettingsAnnouncementsQuestionNotifications';
import hfSettingsAdvisor from './settings_advisor/settings_advisor.controller';
import hfSettingsFeedbackTransparencyConfig from './settings_feedback/transparency_config/feedback_transparency_config.controller';
import hfHrsiIntegrationSettings from './settings_integration/settings_hri_integration/settings_hri_integration.controller';
import SettingsAutoInvite
    from 'root/app/components/settings/settings_employee_provisioning/settings_auto_invite/HfSettingsAutoInvite';

function CompanyConfigCtrl($scope, Restangular, AuthSrvc, CompanySvrc, FeedbackConfigurationService, ErrorSvrc,
    $stateParams, $translate, Settings, Analytics, toaster, Languages, $intercom) {
    'ngInject';

    var _this = this;

    $scope.status = {
        basicOpen: true
    };

    $scope.showConfigNumber = 1;

    $scope.AuthSrvc = AuthSrvc;

    $scope.companySvrc = CompanySvrc;

    $scope.loading = true;

    $scope.errored = false;
    $scope.errorMsg = '';

    $scope.uploadProgress = 0;
    $scope.uploading = false;

    $scope.companyDetails = {};
    $scope.question = {};
    $scope.selectedItem = { id: 'root', name: 'raiz' };
    $scope.orgdata = {};

    $scope.hierarchyLevelsCount = 0;

    $scope.invitationEmail = '';

    $scope.userFilter = null;

    $scope.feedbackTypeDirected = false;

    $scope.languages = Languages.appLanguages;

    $scope.industryTypes = CompanySvrc.industryTypesList;

    $scope.jobEnvironments = CompanySvrc.jobEnvironmentTypesList;

    $scope.currentEmployeesPage = 1;
    $scope.totalEmployees = 0;
    $scope.employees = [];


    $scope.monthlyReporMode = null;

    $scope.companyConfig = {
        voteReminderDays: [],
        alertDays: []
    };

    $scope.feedbackConfiguration = {};

    $scope.statuses = [
        {
            name: $translate.instant('ALL'),
            value: null
        },
        {
            name: $translate.instant('ACTIVES'),
            value: true
        },
        {
            name: $translate.instant('NOT_ACTIVES'),
            value: false
        }
    ];


    $scope.privacyModes = [
        { id: 'OPEN', label: $translate.instant('COMPANY_FEEDBACK_PRIVACY_MODE_OPEN') },
        { id: 'OPTIONAL', label: $translate.instant('COMPANY_FEEDBACK_PRIVACY_MODE_OPTIONAL') },
        { id: 'MANDATORY', label: $translate.instant('COMPANY_FEEDBACK_PRIVACY_MODE_MANDATORY') }
    ];

    $scope.signatureModes = [
        { id: 'ANONYMOUS', label: $translate.instant('COMPANY_FEEDBACK_SIGNATURE_MODE_ANONYMOUS') },
        { id: 'OPTIONAL', label: $translate.instant('COMPANY_FEEDBACK_SIGNATURE_MODE_OPTIONAL') },
        { id: 'MANDATORY', label: $translate.instant('COMPANY_FEEDBACK_SIGNATURE_MODE_MANDATORY') }
    ];

    $scope.employeeSignatureModes = [
        { id: 'ANONYMOUS', label: $translate.instant('COMPANY_FEEDBACK_EMPLOYEE_SIGNATURE_MODE_ANONYMOUS') },
        { id: 'OPTIONAL', label: $translate.instant('COMPANY_FEEDBACK_EMPLOYEE_SIGNATURE_MODE_OPTIONAL') }

    ];

    $scope.employeeRecognitionSignatureModes = [
        { id: 'ANONYMOUS', label: $translate.instant('COMPANY_FEEDBACK_EMPLOYEE_SIGNATURE_MODE_ANONYMOUS') },
        { id: 'OPTIONAL', label: $translate.instant('COMPANY_FEEDBACK_EMPLOYEE_SIGNATURE_MODE_OPTIONAL') },
        { id: 'MANDATORY', label: $translate.instant('COMPANY_FEEDBACK_SIGNATURE_MODE_MANDATORY') }
    ];

    $scope.roles = [
        { id: 'ADVISOR', label: $translate.instant('ADVISOR') },
        { id: 'MANAGER', label: $translate.instant('MANAGER') },
        { id: 'ADMIN', label: $translate.instant('ADMINISTRATOR') }/*,
                {id: 'RESPONSIBLE', label: $translate.instant('RESPONSABLE')}*/
    ];


    $scope.characteristicDateTypes = [
        { id: 'FULL', label: $translate.instant('CHARACTERISTICS_DATE_FULL') },
        { id: 'MONTH', label: $translate.instant('CHARACTERISTICS_DATE_MONTH') },
        { id: 'YEAR', label: $translate.instant('CHARACTERISTICS_DATE_YEAR') }
    ];


    $scope.feedbackModes = [
        { id: 'ON_VOTE', label: $translate.instant('QUESTION_FEEDBACK_MODE_ON_VOTE') },
        { id: 'AFTER_VOTE', label: $translate.instant('QUESTION_FEEDBACK_MODE_AFTER_VOTE') },
        { id: 'ON_AND_AFTER_VOTE', label: $translate.instant('QUESTION_FEEDBACK_MODE_ON_AND_AFTER_VOTE') }
    ];

    $scope.feedbackTypes = [
        { id: false, label: $translate.instant('QUESTION_FEEDBACK_TYPE_NOT_DIRECTED') },
        { id: true, label: $translate.instant('QUESTION_FEEDBACK_TYPE_DIRECTED') }
    ];

    $scope.commentsTransparencyModes = [
        { id: 'ANONYMOUS_OPEN', label: $translate.instant('ANONYMOUS_OPEN_MODE') },
        { id: 'ALL_CLOSED', label: $translate.instant('ALL_CLOSED_MODE') }
    ];


    $scope.employeeCommentsSortModes = [
        { id: 'DATE', label: $translate.instant('EMPLOYEE_COMMENT_SORT_MODE_DATE') },
        { id: 'LAST_UPDATED', label: $translate.instant('EMPLOYEE_COMMENT_SORT_MODE_LAST_UPDATE') }
    ];

    $scope.saveFeedbackSettings = function () {
        FeedbackConfigurationService.updateConfiguration($scope.feedbackConfiguration, function (err, configuration) {

            // TODO: we do this until we split feedback settings from generic configuration
            if (err) {
                ErrorSvrc.showErrorModal(err);
            } else {
                $scope.feedbackConfiguration = configuration;
                toaster.pop('success', null, ($translate.instant('FEEDBACK_CONFIGURATION_UPDATED')));
            }

        });
    };


    $scope.saveCompanyAndSettings = function () {
        $scope.executing = true;
        $scope.errored = false;
        CompanySvrc.saveCompany($scope.companyDetails, function (err, company) {
            if (err)
            {ErrorSvrc.showErrorModal(err);}
            else {
                $scope.companyDetails = company;
                $scope.saveSettings();
            }
        });

    };


    $scope.saveSettings = function () {
        //  $scope.companyConfig.alertHour = this.alertTime.split(':')[0];
        //  $scope.companyConfig.alertMinute = this.alertTime.split(':')[1];

        if ($scope.companyConfig.companyStartDate) {
            $scope.companyConfig.startDate = Dates.toAPIFormat($scope.companyConfig.companyStartDate, Settings.apiDateFormat);
        }

        if ($scope.companyConfig.companyBlockedDate && $scope.companyConfig.status === 'BLOCKED') {
            $scope.companyConfig.blockedDate = Dates.toAPIFormat($scope.companyConfig.companyBlockedDate, Settings.apiDateFormat);
        } else {
            $scope.companyConfig.blockedDate = null;
        }

        if (_this.monthlyReporMode === null) {
            $scope.companyConfig.rolesForMonthlyReport = null;
        } else if (_this.monthlyReporMode === 'ADMIN') {
            $scope.companyConfig.rolesForMonthlyReport = ['ADMIN'];
        } else if (_this.monthlyReporMode === 'ADMIN_MANAGER') {
            $scope.companyConfig.rolesForMonthlyReport = ['ADMIN', 'MANAGER'];
        }

        CompanySvrc.saveCompanyConfig($scope.companyDetails.id, $scope.companyConfig, function (err, data) {
            if (err)
            {ErrorSvrc.showErrorToast(err);}
            else
            {toaster.pop('success', null, ($translate.instant('COMPANY_UPDATED')));}

            getUserCompany();
        });
    };

    $scope.onSaveSettings = function (companyConfig) {
        $scope.companyConfig = companyConfig;
        $scope.saveSettings();

    };


    $scope.hasAdvisor = function () {
        if (typeof $scope.advisor === 'undefined') {
            return false;
        } else {
            return true;
        }
    };

    $scope.editCompany = function () {
        CompanySvrc.editCompany();
        Analytics.trackEvent('settings', 'edit_company');
    };

    $scope.removeDomain = function (domainIndex) {
        $scope.companyConfig.validDomains.splice(domainIndex, 1);
        Analytics.trackEvent('settings', 'remove_domain');
    };

    $scope.addDomain = function (newDomain) {
        $scope.companyConfig.validDomains.push(newDomain);
        $scope.newDomain = null;
        Analytics.trackEvent('settings', 'add_domain');
    };

    $scope.showConfigItem = function (item) {
        $scope.showConfigNumber = item;

        setTimeout(function () {
            $(window).trigger('resize');

        }, 500);
    };

    $scope.isSectionVisible = function (section) {
        if (!section.onlyAdvisors) {
            if (section.requireSetting) {
                return $scope.companyConfig[section.requireSetting];
            } else if (section.requireCompanyFields) {
                return $scope.companyDetails[section.requireCompanyFields];
            } else {
                return true;
            }
        }
        return (AuthSrvc.userHasRole('ADVISOR'));
    };


    _this.sections = _.filter(getSettingsSections($translate), function (section) {
        return $scope.isSectionVisible(section);
    });


    function parseConfig() {
        if ($scope.companyConfig.feedbackTypes)
        {$scope.feedbackTypeDirected = true;}

        // jscs:disable maximumLineLength

        if ($scope.companyConfig.startDate)
        {$scope.companyConfig.companyStartDate = Dates.fromAPIFormat($scope.companyConfig.startDate, Settings.apiDateFormat);}
        if ($scope.companyConfig.blockedDate)
        {$scope.companyConfig.companyBlockedDate = Dates.fromAPIFormat($scope.companyConfig.blockedDate, Settings.apiDateFormat);}
    }

    // Ge the company
    function getUserCompany() {
        CompanySvrc.getUserCompany(function (err, company) {
            if (err) {
                $scope.executing = false;
                $scope.errored = true;
                $scope.errorMsg = ErrorSvrc.getErrorMessage(err);
                $scope.loading = false;
            } else {
                $scope.executing = false;
                $scope.errored = false;
                $scope.companyDetails = Restangular.copy(company);
                $scope.loading = false;
                if (AuthSrvc.user() && AuthSrvc.user().role != 'MANAGER') {
                    CompanySvrc.getUserCompanyConfig(function (err, companyConfig) {
                        if (err) {
                            ErrorSvrc.showErrorModal(err);
                            console.log('Error loading company config');
                        } else {
                            $scope.companyConfig = companyConfig;

                            parseConfig();



                            // jscs:enable maximumLineLength

                            _this.sections = _.filter(getSettingsSections($translate), function (section) {
                                return $scope.isSectionVisible(section);
                            });

                            if ($stateParams.section) {
                                var sectionCode = parseInt($stateParams.section);
                                var sectionsByCodes = _this.sections.map(function (section) {
                                    var response = {};
                                    response.codes = section.childs.map(function (child) {
                                        return child.code;
                                    });
                                    response.status = section.statusCode;
                                    return response;
                                });

                                var selectedSection = sectionsByCodes.filter(function (item) {
                                    return item.codes.indexOf(sectionCode) > -1;
                                });

                                if (selectedSection) {
                                    $scope.showConfigItem(sectionCode);
                                    $scope.status = {};
                                    $scope.status[selectedSection[0].status] = true;
                                }


                             
                            }

                        
                            const hasRolesForMonthlyReport = $scope.companyConfig?.rolesForMonthlyReport;
                            const hasAdmin = hasRolesForMonthlyReport?.includes('ADMIN');
                            const hasManager = hasRolesForMonthlyReport?.includes('MANAGER');
        
                            if (hasRolesForMonthlyReport && hasAdmin && hasManager) {
                                _this.monthlyReporMode = 'ADMIN_MANAGER';
                            } else if (hasRolesForMonthlyReport && hasAdmin) {
                                _this.monthlyReporMode = 'ADMIN';
                            } else {
                                _this.monthlyReporMode = null;
                            }
        
                        }
                    });
                }
            }
            $scope.$apply();
        });
    }

    this.$onInit = function () {



        if (AuthSrvc.isADVISOR()) {
            $scope.employeeSignatureModes.push(
                { id: 'PUBLIC_MANDATORY', label: $translate.instant('COMPANY_FEEDBACK_SIGNATURE_MODE_PUBLIC_MANDATORY') }
            );

            $scope.signatureModes.push(
                { id: 'PUBLIC_MANDATORY', label: $translate.instant('COMPANY_FEEDBACK_SIGNATURE_MODE_PUBLIC_MANDATORY') }
            );
        }







        getUserCompany();


        FeedbackConfigurationService.getConfiguration(function (err, configuration) {
            if (err)
            {ErrorSvrc.showErrorModal(err);}
            else {
                $scope.feedbackConfiguration = configuration;
                $scope.recognitionValues = configuration.recognitionValues;

                if (configuration.feedbackLevelMode === 'NO_FEEDBACK') {
                    $scope.feedbackLevel = 1;
                } else if (configuration.feedbackLevelMode === 'ALL_CLOSED') {
                    $scope.feedbackLevel = 2;
                } else if (configuration.feedbackLevelMode === 'ANONYMOUS_ON_REPLY') {
                    $scope.feedbackLevel = 3;
                } else {
                    $scope.feedbackLevel = 4;
                }

            }

        });



        if ($stateParams.section) {
            var sectionCode = parseInt($stateParams.section);
            var sectionsByCodes = _this.sections.map(function (section) {
                var response = {};
                response.codes = section.childs.map(function (child) {
                    return child.code;
                });
                response.status = section.statusCode;
                return response;
            });

            var selectedSection = sectionsByCodes.filter(function (item) {
                return item.codes.indexOf(sectionCode) > -1;
            });

            if (selectedSection) {
                $scope.showConfigItem(sectionCode);
                $scope.status = {};
                $scope.status[selectedSection[0].status] = true;
            }

        }




        Analytics.trackEvent('settings');

        $intercom.trackEvent('dashboard_visited_settings');
    };
}



const SettingsView = function($stateProvider) {
    'ngInject';
    $stateProvider.state('company.config', {
        url: '/config/:section',
        controller: CompanyConfigCtrl,
        controllerAs: 'st',
        template: template,
        params: {
            section: { squash: true, value: null }
        },
        scope: {
            section: '='
        },
        data: { pageTitle: 'SIDEMENU_CONFIG', bodyClasses: 'company-configuration', roles: ['ADMIN', 'ADVISORS']  }
    });
};

export default SettingsView;
